<script setup lang="ts">
import { Button, Collapsible, CollapsibleTrigger } from '@laam/ui/base';

interface Props {
	description: string;
}

const isOpen = ref(false);

defineProps<Props>();
</script>
<template>
	<Collapsible
		v-model:open="isOpen"
		class="relative mt-3xl mx-3xl lg:mx-0 max-h-[80px] overflow-y-auto"
	>
		<div class="flex w-full gap-md items-end justify-start">
			<span
				class="text-justify w-[90%]"
				:class="{
					truncate: !isOpen,
				}"
			>
				{{ description }}
			</span>
			<CollapsibleTrigger as-child>
				<Button
					variant="plain"
					size="xs"
					class="text-gray-900 font-semibold !p-0 sticky bottom-0"
				>
					{{ isOpen ? 'View Less' : 'Read More' }}
				</Button>
			</CollapsibleTrigger>
		</div>
	</Collapsible>
</template>
